import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
  useRef,
} from "react";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RafClearDialogContent,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNotNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFRiskRegisterType,
} from "../../../constants/Common/Constants";
import UpdateRisk from "./UpdateRisk";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";

interface IProps {
  riskRegisterRow: any;
  onSave: () => void;
  riskRegisterEntity: EntityRow;
  uiMode?: "ListStyle" | "CardStyle";
  riskType?: RAFRiskRegisterType.Risk | RAFRiskRegisterType.HealthCondition;
  allowEdit?: boolean;
  cardClassName?: string;
  theme?: "White" | "default";
}

interface IState {
  showUpdateRiskRegister: boolean;
}

function RiskRegisterContent({
  riskRegisterRow,
  uiMode = "CardStyle",
  ...props
}: PropsWithChildren<IProps>) {
  let deleteDialog: any;

  const riskRegisterModule = CareEsioEntity.RiskRegister.EntityName;
  let updateRiskRegisterDialogRef = useRef<DialogComponent>(null);

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;
  const hasPermissionToEdit = hasPermission(
    permissionValue,
    `${riskRegisterModule}::Update`
  );

  const allowEdit =
    props.allowEdit === true && hasPermissionToEdit === true ? true : false;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showUpdateRiskRegister: false,
    }
  );

  //UpdateRiskRegiester start
  const showUpdateUpdateRiskRegisterDialog = () => {
    if (hasPermissionToEdit) {
      setState({ showUpdateRiskRegister: true });
    }
  };

  const updateUpdateRiskRegisterContent = () => {
    if (state.showUpdateRiskRegister) {
      return (
        <UpdateRisk
          riskRegisterRow={riskRegisterRow}
          objectUID={riskRegisterRow.UID}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeUpdateRiskRegiesterDialog.bind(this)}
          onDelete={onClickDeleteSection}
          riskType={props.riskType}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnUpdate = async () => {
    await RafClearDialogContent(updateRiskRegisterDialogRef);
    setState({ showUpdateRiskRegister: false });
    if (props.onSave) {
      props.onSave();
    }
  };

  const closeUpdateRiskRegiesterDialog = async () => {
    await RafClearDialogContent(updateRiskRegisterDialogRef);

    setState({ showUpdateRiskRegister: false });
  };
  //UpdateRiskRegiester end

  //delete item start
  const onClickDeleteSection = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete ${props.riskType}`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    let isDeleted = await DeleteRecord(
      riskRegisterRow.UID,
      riskRegisterModule,
      props.riskRegisterEntity.UID
    );

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      refreshOnUpdate();
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  const RiskLevel = (colClassName?) => {
    return (
      <RAFDetailFieldCustom
        value={
          isNotNullAndUndefined(riskRegisterRow.RiskLevel)
            ? riskRegisterRow.RiskLevel
            : "Not set"
        }
        title="RiskLevel"
        moduleName={riskRegisterModule}
        isColorOption
        field="RiskLevel"
        mode="textOnly"
        labelDivClassName="col-auto"
        valueDivClassName="col-auto"
        {...(isNotNullAndUndefined(colClassName) && {
          colClassName: colClassName,
        })}
        labelClassName="text-transform-none details-label"
        rowClassName="gx-0 gy-1 flex-column"
      />
    );
  };

  const Likelihood = (colClassName?) => {
    return (
      <RAFDetailFieldCustom
        value={
          isNotNullAndUndefined(riskRegisterRow.Likelihood)
            ? riskRegisterRow.Likelihood
            : "Not set"
        }
        title="Likelihood"
        moduleName={riskRegisterModule}
        isColorOption
        field="Likelihood"
        mode="textOnly"
        labelClassName="text-transform-none details-label"
        labelDivClassName="col-auto"
        valueDivClassName="col-auto"
        {...(isNotNullAndUndefined(colClassName) && {
          colClassName: colClassName,
        })}
        rowClassName="gx-0 gy-1 flex-column"
      />
    );
  };

  const riskRegisterListContent = () => {
    return (
      <div>
        <div>
          <span className="header-text">{riskRegisterRow.Title}</span>
        </div>
        {isNotNullAndUndefined(riskRegisterRow.Mitigation) ? (
          <CustomCardWidget
            cardContentClassName="p-2"
            cardClassName="border-0 surface_neutral_base"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(riskRegisterRow.Mitigation),
              }}
              className="text_secondary_value mt-1 description-text"
            ></div>
          </CustomCardWidget>
        ) : (
          // <div className="mt-2 secondary-text fst-italic">{riskRegisterRow.Mitigation}</div>
          ""
        )}
        <div className="detailsLeftCard-second-title h-26 mt-2">
          <div className="w-100">
            <div className="row gx-3 flex-nowrap align-items-center">
              {isNotNullAndUndefined(riskRegisterRow.RiskLevel) && (
                <div className="col-auto detailsLeftCard-second-title-col">
                  <div className="detailsLeftCard-second-title-item">
                    <div className="me-1">
                      <RAFDetailFieldCustom
                        value={
                          isNotNullAndUndefined(riskRegisterRow.RiskLevel)
                            ? riskRegisterRow.RiskLevel
                            : "Not set"
                        }
                        title="RiskLevel"
                        moduleName={riskRegisterModule}
                        isColorOption
                        field="RiskLevel"
                        showLabel={false}
                        mode="outlineView"
                      />
                      {/* <span className={`e-badge e-badge-${riskRegisterRow.RiskLevel}`}>{riskRegisterRow.RiskLevel}</span> */}
                    </div>
                    <div>
                      {isNotNullAndUndefined(riskRegisterRow.Likelihood) && (
                        <RAFDetailFieldCustom
                          value={
                            isNotNullAndUndefined(riskRegisterRow.Likelihood)
                              ? riskRegisterRow.Likelihood
                              : "Not set"
                          }
                          title="Likelihood"
                          moduleName={riskRegisterModule}
                          isColorOption
                          field="Likelihood"
                          showLabel={false}
                          mode="outlineView"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {false && isNotNullAndUndefined(riskRegisterRow.Likelihood) && (
                <div className="col-auto detailsLeftCard-second-title-col">
                  <div className="detailsLeftCard-second-title-item">
                    <RAFDetailFieldCustom
                      value={
                        isNotNullAndUndefined(riskRegisterRow.Likelihood)
                          ? riskRegisterRow.Likelihood
                          : "Not set"
                      }
                      title="Likelihood"
                      moduleName={riskRegisterModule}
                      isColorOption
                      field="Likelihood"
                      showLabel={false}
                      mode="outlineView"
                    />
                    {/* <span className={`e-badge e-badge-border`}>{riskRegisterRow.Likelihood}</span> */}
                  </div>
                </div>
              )}
              <div className="col-auto detailsLeftCard-second-title-col">
                <div
                  className="detailsLeftCard-second-title-item"
                  onClick={(e) => e.stopPropagation()}
                >
                  <RAFRecordInfo
                    createdBy={riskRegisterRow.CreatedBy}
                    createdDate={riskRegisterRow.CreatedDate}
                    modifiedDate={riskRegisterRow.ModifiedDate}
                    modifiedBy={riskRegisterRow.ModifiedBy}
                    lastActivityDate={riskRegisterRow.LastActivityDate}
                    dropdownBtnClassName="custom-button-sm"
                    hideClockIcon
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getSubjectTemplate = () => {
    return (
      <div className="row g-0 gy-2 align-items-center justify-content-between">
        <div className="col-12">
          <div className="subtitle_1 semi_bold">
            {riskRegisterRow.Title ?? "not set"}
          </div>
        </div>
        {isNotNullAndUndefined(riskRegisterRow.Mitigation) && (
          <div className="col-12">
            <CustomCardWidget cardContentClassName="p-2">
              <span className="body_2_light">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(riskRegisterRow.Mitigation),
                  }}
                  className="body_2_light description-text"
                ></div>
              </span>
            </CustomCardWidget>
          </div>
        )}
        <div className="col-12 mt-3">
          <RAFDetailsValueWithSeparator
            outerClassName="line-seprator h-auto"
            rowClassName="flex-nowrap gx-3"
          >
            {RiskLevel()}
            {Likelihood()}
          </RAFDetailsValueWithSeparator>
        </div>
      </div>
    );
  };

  let customFooter = (
    <div>
      <div className="custom__card__separator custom__card__separator__light"></div>
      <div className="pe-3 py-2">
        <div className="row gx-2 align-items-center">
          {/* <div className="col-auto"></div> */}
          <div className="col-auto">
            <RAFRecordInfo
              createdBy={riskRegisterRow.CreatedBy}
              createdDate={riskRegisterRow.CreatedDate}
              modifiedDate={riskRegisterRow.ModifiedDate}
              modifiedBy={riskRegisterRow.ModifiedBy}
              lastActivityDate={riskRegisterRow.LastActivityDate}
              spanContent="Updated "
              dropdownBtnClassName="custom-button-sm"
              spanContentClassName="body_3_light medium"
              hideClockIcon
            />
          </div>
          {allowEdit ? (
            <div className="col-auto ms-auto">
              <RAFButtonComponent
                action={RAFButtonConstant.Edit}
                iconBtn
                className="btn_state_warning white e-round"
                onClick={showUpdateUpdateRiskRegisterDialog}
              ></RAFButtonComponent>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );

  if (isNotNullAndUndefined(riskRegisterRow)) {
    return (
      <div className="col-12" key={riskRegisterRow.UID}>
        {uiMode === "ListStyle" ? (
          <div
            className={`border-bottom ${hasPermissionToEdit ? "pointer" : ""}`}
            {...(allowEdit
              ? { onClick: showUpdateUpdateRiskRegisterDialog }
              : {})}
            // onClick={showUpdateUpdateRiskRegisterDialog}
          >
            {/* <div className={`custom__card custom__card__leftBorder clickable shadow-sm ${riskRegisterRow.RiskLevel}`} onClick={showUpdateUpdateRiskRegisterDialog}>
                    <div className="custom__card__content p-3 py-2"> */}
            <div
              style={{
                margin: "2px 0",
                borderLeft: "3px solid rgb(187, 187, 187)",
              }}
            >
              {getSubjectTemplate()}
              {customFooter}
            </div>
          </div>
        ) : (
          <CustomCardWidget
            cardClassName={`${
              IsNotNullOrWhiteSpace(props.cardClassName)
                ? props.cardClassName
                : ""
            }${
              allowEdit && hasPermissionToEdit
                ? IsNotNullOrWhiteSpace(props.cardClassName)
                  ? " pointer "
                  : "pointer "
                : ""
            }${
              props.theme === "White" ? "" : " surface_neutral_base"
            } overflow-hidden`}
            // cardClassName={`care_recipient_care_plan_item${allowEdit ? " pointer" : ''}`}
            {...(allowEdit
              ? { onClick: showUpdateUpdateRiskRegisterDialog }
              : {})}
            footerTemplate={customFooter}
          >
            {getSubjectTemplate()}
          </CustomCardWidget>
        )}
        {/* </div> */}
        {/* </div> */}
        {state.showUpdateRiskRegister && (
          <DialogComponent
            header={`Update ${props.riskType}`}
            showCloseIcon
            visible={state.showUpdateRiskRegister}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${riskRegisterModule}`}
            content={updateUpdateRiskRegisterContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeUpdateRiskRegiesterDialog.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
            ref={updateRiskRegisterDialogRef}
          ></DialogComponent>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(RiskRegisterContent);
