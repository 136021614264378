import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  forwardRef,
  PropsWithChildren,
  Reducer,
  useEffect,
  useImperativeHandle,
  useReducer,
} from "react";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RiskRegisterPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  ContentType,
  RAFButtonConstant,
  RAFRiskRegisterType,
} from "../../../constants/Common/Constants";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import {
  getAllAttributes,
  getFields,
  getRelatedRecords,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  triggerRAFEvent,
  unsubscribeRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  ConvertSystemName,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFDetailFieldEditableCustom from "../../../RAFComponents/Navigation/RAFDetailFieldEditableCustom";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import { getDefaultCareAssessmentFieldValue } from "../CareAssessmentLibrary/CareAssessmentHelper";
import CreateRisk from "./CreateRisk";
import RiskRegisterContent from "./RiskRegisterContent";

interface IProps {
  careRecipientUID: string;
  careAssessmentUID?: string;
  showEmptyState?: boolean;
  showAddBtn?: boolean;
  allowEdit?: boolean;
  careAssessmentRow?: any;
  uiStyle?:
    | "CardStyle"
    | "DetailsStyle"
    | "ListStyle"
    | "CollapsePanelStyle"
    | "CollapsePanelWithOutCardStyle"
    | "ProfileSummaryStyle"
    | "SummaryStyle"
    | "SummaryDetails";
  onRiskUpdated?: () => void;
  riskType?: RAFRiskRegisterType.Risk | RAFRiskRegisterType.HealthCondition;
  paddingClass?: string;
  guttersClass?: string;
  collapsible?: boolean;
  cardClassName?: string;
  includeHeaderClass?: boolean;
  customCardClass?: string;
  careRecipientRow?: any;
  theme?: "White" | "default";
}

interface IState {
  isLoading: boolean;
  riskRegisterItems: any;
  riskRegisterEntity: EntityRow;
  showCreateRiskRegisterContent: boolean;
  defaultFieldValue: string;
}

const RiskRegisterList = forwardRef(function RiskRegisterList(
  {
    showAddBtn = true,
    showEmptyState = true,
    uiStyle = "CardStyle",
    paddingClass = "p-2-5 p-md-3",
    includeHeaderClass = true,
    allowEdit = true,

    ...props
  }: PropsWithChildren<IProps>,
  ref
) {
  const riskRegisterModule = CareEsioEntity.RiskRegister.EntityName;
  const rafObject = React.useContext(RAFObjectContext);
  const careRecipientRow = isNotNullAndUndefined(rafObject)
    ? rafObject.rafObject
    : props.careRecipientRow;
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      riskRegisterItems: null,
      riskRegisterEntity: null,
      showCreateRiskRegisterContent: false,
      defaultFieldValue: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.careAssessmentUID]);

  useImperativeHandle(ref, () => ({
    toggleCreateDlg() {
      setState({
        showCreateRiskRegisterContent: !state.showCreateRiskRegisterContent,
      });
    },
  }));

  useEffect(() => {
    const relatedModule = isNotNullAndUndefined(props.careAssessmentUID)
      ? `${CareEsioEntity.CarePlan.EntityName}_${riskRegisterModule}`
      : riskRegisterModule;

    const eventKey = `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`;
    subscribeRAFEvent(eventKey, reLoadListContent);

    return () => {
      unsubscribeRAFEvent(eventKey, reLoadListContent);
    };
  });

  const reLoadListContent = (args) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.detail)) {
      const argsUIStyle = args.detail.uiStyle;
      if (argsUIStyle === uiStyle) {
        refresh();
      }
    }
  };

  const refresh = async () => {
    if (
      isNotNullAndUndefined(props.careAssessmentUID) ||
      isNotNullAndUndefined(props.careRecipientUID)
    ) {
      setState({
        isLoading: true,
        riskRegisterItems: null,
        showCreateRiskRegisterContent: false,
      });

      const [riskRegisterEntity, attributeRelatedList, queryAttributes] =
        await Promise.all([
          getEntityByObjectName({
            ObjectName: ConvertSystemName(riskRegisterModule),
          }),
          getAllAttributes(ConvertSystemName(riskRegisterModule)),
          getFields(ConvertSystemName(riskRegisterModule)),
        ]); //dont remove this line  details filed is used in loop below this line add to avoid multiple calls

      const riskRegisterItems = isNotNullAndUndefined(props.careAssessmentUID)
        ? await getRelatedRecords(
            riskRegisterModule,
            null,
            "AssessmentUID",
            props.careAssessmentUID,
            riskRegisterEntity.UID
          )
        : await getRelatedRecords(
            riskRegisterModule,
            null,
            "CareRecipientUID",
            props.careRecipientUID,
            riskRegisterEntity.UID
          );

      const defaultFieldValue = await getDefaultCareAssessmentFieldValue(
        props.careAssessmentRow
      );

      let filteredRiskRegisterItems;

      if (isNotEmptyArray(riskRegisterItems)) {
        filteredRiskRegisterItems = riskRegisterItems.filter(
          (x) => x.RiskType === props.riskType
        );
      }

      setState({
        isLoading: false,
        riskRegisterItems: filteredRiskRegisterItems,
        defaultFieldValue,
        riskRegisterEntity,
      });
    }
  };

  const onSaved = () => {
    refresh();
    if (isNotNullAndUndefined(props.onRiskUpdated)) {
      props.onRiskUpdated();
    } else {
      const relatedModule = isNotNullAndUndefined(props.careAssessmentUID)
        ? `${CareEsioEntity.CarePlan.EntityName}_${riskRegisterModule}`
        : riskRegisterModule;

      const eventKey = `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`;
      triggerRAFEvent(eventKey, {
        uiStyle: `ProfileSummaryStyle`,
      });
    }
  };

  const getRiskRegisterListContent = () => {
    const riskCount = isNotEmptyArray(state.riskRegisterItems)
      ? state.riskRegisterItems.length
      : 0;

    if (uiStyle === "DetailsStyle") {
      return (
        <div className="row g-3">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="header_5">
                <span>{CareEsioEntity.RiskRegister.CollectionName}</span>
              </div>
              <div>
                {state.isLoading === false && showAddBtn === true && (
                  <div>
                    <RAFPermissionRender
                      permissionName={
                        RiskRegisterPermissionConstants.RiskRegisterAdd
                      }
                    >
                      <RAFButtonComponent
                        className="btn_brand_primary semi_bold"
                        action={RAFButtonConstant.Add}
                        onClick={() => onClickAddRiskRegister()}
                      />
                    </RAFPermissionRender>
                    {createRiskDlgDiv()}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row g-3">{riskRegisterListItem()}</div>
          </div>
          {false && (
            <>
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="header_5">
                    <span>{`${CareEsioEntity.RiskRegister.DisplayName} Details`}</span>
                  </div>
                  {/* <div>
                <div>
                  <RAFButtonComponent
                    className="btn_brand_primary"
                    action={RAFButtonConstant.Edit}
                  // onClick={() => onClickAddRiskRegister()}
                  />
                </div>
              </div> */}
                </div>
              </div>
              <div className="col-12">
                <div
                  style={{ margin: "0 -1rem" }}
                  className="surface_neutral_base border-top border-bottom"
                >
                  <CustomCardWidget cardClassName="m-3 border-0">
                    <RAFDetailFieldEditableCustom
                      title="Key care details"
                      value={
                        isNotNullAndUndefined(careRecipientRow)
                          ? careRecipientRow.OtherPreferencesImpact
                          : ""
                      }
                      onChange={(e) =>
                        onChangeCareRecipient("OtherPreferencesImpact", e)
                      }
                      type={RAFDataType.Multiline}
                      name="OtherPreferencesImpact"
                      disabled
                    />
                  </CustomCardWidget>
                </div>
              </div>
            </>
          )}
        </div>
      );
    } else if (
      uiStyle === "CollapsePanelStyle" ||
      uiStyle === "CollapsePanelWithOutCardStyle"
    ) {
      return (
        <div className="col-12">
          <div
            className={`${
              isNotNullAndUndefined(props.customCardClass)
                ? `${props.customCardClass} `
                : ""
            }${
              uiStyle === "CollapsePanelWithOutCardStyle" ? "" : "custom__card"
            }`}
          >
            <div
              className={
                uiStyle === "CollapsePanelWithOutCardStyle"
                  ? ""
                  : "custom__card__content p-0"
              }
            >
              <RAFCollapseWithPlusIcon
                toggleArrowIcon="Arrow"
                title={CareEsioEntity.RiskRegister.CollectionName}
                allowFullRowClick
                isCollapsed={true}
                showSeparator={
                  uiStyle === "CollapsePanelWithOutCardStyle" ? false : true
                }
                collapsePanelHeaderClass={`${
                  includeHeaderClass
                    ? ""
                    : "collapsePanel__header__fixedHeight__lg"
                }${
                  uiStyle === "CollapsePanelWithOutCardStyle"
                    ? includeHeaderClass
                      ? " p-0"
                      : "p-0"
                    : ""
                }`}
                collapsePanelContentClassname={`${
                  uiStyle === "CollapsePanelWithOutCardStyle"
                    ? "p-0 pt-3 "
                    : "p-2 p-md-3 "
                }"relatedListOuterDiv border-top-0"`}
                badge={{
                  count: riskCount,
                  show: true,
                  className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
                }}
                customButton={
                  showAddBtn ? (
                    <RAFPermissionRender
                      permissionName={
                        RiskRegisterPermissionConstants.RiskRegisterAdd
                      }
                    >
                      {uiStyle === "CollapsePanelWithOutCardStyle" ? (
                        <RAFButtonComponent
                          className={"link-button text-decoration-underline"}
                          action={RAFButtonConstant.Add}
                          onClick={() => onClickAddRiskRegister()}
                          btnContent="Add Risk"
                          showIcon={false}
                        />
                      ) : (
                        <RAFButtonComponent
                          className={"custom-button-sm primary-custom-button"}
                          // className="custom-button-md"
                          action={RAFButtonConstant.Add}
                          btnContent={`Add Risk`}
                          onClick={() => onClickAddRiskRegister()}
                        />
                      )}
                    </RAFPermissionRender>
                  ) : (
                    ""
                  )
                }
              >
                <div>
                  {createRiskDlgDiv()}
                  {riskRegisterListItem()}
                </div>
              </RAFCollapseWithPlusIcon>
            </div>
          </div>
        </div>
      );
    } else if (uiStyle === "ProfileSummaryStyle") {
      return (
        <div className="" key={riskCount}>
          <div className="row gx-0 gy-2">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <div className="profile_card_details_label">{"Risks"}</div>
                <div
                  className={`ms-2 raf-badge-circle raf-badge${
                    riskCount > 0 ? "-tertiary-circle" : "-neutral-circle"
                  }`}
                >
                  {riskCount}
                </div>
              </div>
            </div>
            {riskCount > 0 ? (
              <div className="col-12">{riskListItemSummary()}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (uiStyle === "SummaryStyle") {
      return (
        <div className="" key={riskCount}>
          <CustomCardWidget cardContentClassName="p-0">
            <RAFCollapseWithPlusIcon
              toggleArrowIcon="Arrow"
              title="Risks"
              badge={{
                count: riskCount,
                show: true,
                className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
              }}
              collapsePanelHeaderClass={
                "collapsePanel__header__fixedHeight__lg"
              }
              isCollapsed={riskCount > 0 ? false : true}
              collapsible={riskCount > 0 ? true : false}
              collapsePanelContentClassname={paddingClass}
            >
              {riskListItemSummary()}
            </RAFCollapseWithPlusIcon>
          </CustomCardWidget>
        </div>
      );
    } else if (uiStyle === "SummaryDetails") {
      return (
        <div className="">
          <CustomCardWidget cardContentClassName="p-0">
            <RAFCollapseWithPlusIcon
              toggleArrowIcon="Arrow"
              title="Risks"
              collapsePanelHeaderClass={
                "collapsePanel__header__fixedHeight__lg"
              }
              // collapsePanelHeaderClass={"px-0"}
              allowFullRowClick
              collapsePanelContentClassname="p-0"
              badge={{
                count: riskCount,
                show: true,
                className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
              }}
              isCollapsed={false}
              // collapsible={props.collapsible}
              showSeparator={true}
              customButton={
                <div>
                  {showAddBtn !== false && (
                    <div className="d-flex align-items-baseline">
                      <div className="col-auto">
                        <RAFPermissionRender
                          permissionName={
                            RiskRegisterPermissionConstants.RiskRegisterAdd
                          }
                        >
                          <RAFButtonComponent
                            className={`custom-button-sm primary-custom-button`}
                            // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                            // className="custom-button-md"
                            action={RAFButtonConstant.Add}
                            // {...!BrowserIsDevice ?
                            //   { btnContent: `Add ${CareEsioEntity.RiskRegister.DisplayName}` }
                            //   :
                            //   {}
                            // }
                            onClick={() => onClickAddRiskRegister()}
                          />
                        </RAFPermissionRender>
                      </div>
                    </div>
                  )}
                </div>
              }
            >
              <div>
                {createRiskDlgDiv()}
                {riskRegisterListItem()}
              </div>
            </RAFCollapseWithPlusIcon>
          </CustomCardWidget>
        </div>
      );
    } else {
      return (
        <div className="col-12">
          <CustomCardWidget cardContentClassName="p-0">
            <RAFCollapseWithPlusIcon
              toggleArrowIcon="Arrow"
              title={`${props.riskType}s`}
              collapsePanelHeaderClass={
                "collapsePanel__header__fixedHeight__lg"
              }
              // collapsePanelHeaderClass={"px-0"}
              allowFullRowClick
              collapsePanelContentClassname="p-0"
              badge={{
                count: riskCount,
                show: true,
                className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
              }}
              isCollapsed={true}
              // collapsible={props.collapsible}
              showSeparator={true}
              customButton={
                <div>
                  {showAddBtn !== false && (
                    <div className="d-flex align-items-baseline">
                      <div className="col-auto">
                        <RAFPermissionRender
                          permissionName={
                            RiskRegisterPermissionConstants.RiskRegisterAdd
                          }
                        >
                          <RAFButtonComponent
                            className={`custom-button-sm primary-custom-button`}
                            // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                            // className="custom-button-md"
                            action={RAFButtonConstant.Add}
                            // {...!BrowserIsDevice ?
                            //   { btnContent: `Add ${CareEsioEntity.RiskRegister.DisplayName}` }
                            //   :
                            //   {}
                            // }
                            onClick={() => onClickAddRiskRegister()}
                          />
                        </RAFPermissionRender>
                      </div>
                    </div>
                  )}
                </div>
              }
            >
              <div>
                {createRiskDlgDiv()}
                {riskRegisterListItem()}
              </div>
            </RAFCollapseWithPlusIcon>
          </CustomCardWidget>
        </div>
      );
    }
  };

  const createRiskDlgDiv = () => {
    if (state.showCreateRiskRegisterContent) {
      return (
        <DialogComponent
          header={`Add ${props.riskType}`}
          showCloseIcon
          visible={state.showCreateRiskRegisterContent}
          cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
          id={`dlg_create_${riskRegisterModule}`}
          content={createRiskRegisterContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeCreateRiskRegisterDialog.bind(this)}
          zIndex={1200}
          open={PreventFocusOnDialogOpen}
        />
      );
    } else {
      return null;
    }
  };

  //addRisk start
  const onClickAddRiskRegister = async () => {
    setState({ showCreateRiskRegisterContent: true });
  };

  const createRiskRegisterContent = () => {
    if (state.showCreateRiskRegisterContent) {
      const { careAssessmentRow } = props;

      let initialValues = {};
      if (isNotNullAndUndefined(careAssessmentRow)) {
        initialValues["CareRecipientUID"] =
          careAssessmentRow["care_recipientuid"];
        initialValues["CareRecipient"] = careAssessmentRow["care_recipient"];
        initialValues["CarePlan"] = careAssessmentRow["care_plan"];
        initialValues["CarePlanUID"] = careAssessmentRow["care_planuid"];
        initialValues["Category"] = careAssessmentRow["category"];
        initialValues["CategoryUID"] = careAssessmentRow["categoryuid"];
        initialValues["Assessment"] = careAssessmentRow["title"];
        initialValues["AssessmentUID"] = careAssessmentRow["UID"];
        initialValues["AssessmentType"] =
          CareEsioEntity.CareAssessment.EntityName;
        initialValues["RiskType"] = props.riskType;
      } else {
        const objectContext = React.useContext(RAFObjectContext);
        const rafObject = isNotNullAndUndefined(objectContext)
          ? objectContext.rafObject
          : null;
        if (isNotNullAndUndefined(rafObject)) {
          initialValues["CareRecipientUID"] = rafObject.UID;
          initialValues["CareRecipient"] = rafObject.recipient_name;
          initialValues["RiskType"] = props.riskType;
          initialValues["AssessmentUID"] = null;
        }
      }

      return (
        <CreateRisk
          initialValues={initialValues}
          onSave={onSaved.bind(this)}
          onClose={closeCreateRiskRegisterDialog.bind(this)}
          moduleName={riskRegisterModule}
          riskType={props.riskType}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeCreateRiskRegisterDialog = () => {
    setState({ showCreateRiskRegisterContent: false });
  };

  //addRisk end

  const riskRegisterListItem = () => {
    if (state.isLoading === false) {
      if (isNotEmptyArray(state.riskRegisterItems)) {
        return (
          <div className="col-12">
            <div className={`custom__card__content ${paddingClass}`}>
              <div
                className={`row${
                  isNotNullAndUndefined(props.guttersClass)
                    ? ` ${props.guttersClass}`
                    : " gy-2 gy-md-3 g-0"
                }`}
              >
                {state.riskRegisterItems.map((item) => {
                  return (
                    <RiskRegisterContent
                      key={item.UID}
                      riskRegisterRow={item}
                      riskRegisterEntity={state.riskRegisterEntity}
                      onSave={onSaved}
                      riskType={props.riskType}
                      allowEdit={uiStyle === "SummaryStyle" ? false : allowEdit}
                      cardClassName={props.cardClassName}
                      theme={props.theme}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      } else if (showEmptyState !== false) {
        return (
          <div className="col-12">
            <RAFEmptyState
              emptyStateContainerClass={
                props.theme === "White" ? "surface_neutral_light" : ""
              }
              title={`No ${props.riskType} recorded.`}
              {...(showAddBtn === true && {
                body: `To get started, please add a new ${props.riskType} by clicking on the "Add" button.`,
              })}
            />
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return (
        <div className="col-12">
          <div className="custom__card__content p-0">
            <div className="container-fluid px-0">
              <ACLoadingPanel loadingText="Preparing Data..." />
            </div>
          </div>
        </div>
      );
    }
  };

  const riskListItemSummary = () => {
    if (isNotEmptyArray(state.riskRegisterItems)) {
      return (
        <div className="row g-2">
          {state.riskRegisterItems.map((item) => {
            return (
              <div className={"col-auto"} key={item.UID}>
                <div className="raf_badge surface_neutral_base">
                  <span className="body_2_dark ecllipseSecondLine">
                    {item.Title}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const getBodyContent = () => {
    if (
      isNotNullAndUndefined(props.careAssessmentUID) ||
      isNotNullAndUndefined(props.careRecipientUID)
    ) {
      if (showEmptyState === false) {
        if (state.isLoading) {
          return (
            <div className="p-3">
              <div className="row g-3">
                <div className="container-fluid px-0">
                  <ACLoadingPanel loadingText="Preparing Data..." />
                </div>
              </div>
            </div>
          );
        } else {
          // if (isNotEmptyArray(state.riskRegisterItems)) {
          return getRiskRegisterListContent();
          // } else {
          //   return <div>{createRiskDlgDiv()}</div>;
          // }
        }
      } else {
        return getRiskRegisterListContent();
      }
    } else {
      return <div></div>;
    }
  };

  function onChangeCareRecipient(fieldName: keyof any, e: any): void {
    (careRecipientRow[fieldName] as any) = e.value;
    if (isNotNullAndUndefined(careRecipientRow)) {
      let id = careRecipientRow.UID;
      const url = "DataList/Save";
      let saveRequestData = getSaveRequest(careRecipientRow, id);
      saveRequestData.EntityName = CareEsioEntity.CareRecipient.EntityName;
      repositoryActions
        .postDataAndGetResponse(
          url,
          saveRequestData,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (response !== null && response !== undefined) {
            if (
              response.data.EntityId !== null &&
              response.data.EntityId !== undefined &&
              response.data.EntityId !== ""
            ) {
            } else if (
              response.data.Error !== null &&
              response.data.Error !== undefined &&
              response.data.Error !== ""
            ) {
              //console.log("Error on save", response.data.Error);
            }
          }
        })
        .catch((error) => error);
    }
  }

  return (
    <RAFPermissionRender
      permissionName={RiskRegisterPermissionConstants.RiskRegisterRead}
    >
      {getBodyContent()}
    </RAFPermissionRender>
  );
});

export default React.memo(RiskRegisterList);
